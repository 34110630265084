import { createContext, useState, useEffect, useContext } from 'react';
import { decrypt, encrypt } from '../../../Functions/crypt';
import axios from 'axios';
import { useQuery,useQueryClient } from '@tanstack/react-query';
import { jwtDecode } from 'jwt-decode';
import { useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';


const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

  const navigate = useNavigate();
  const queryClient = useQueryClient(); // Get the queryClient instance

  const [idleTimeout, setIdleTimeout] = useState(null);
  const [changePassword, setChangePassword] = useState(false)
  const [checkoutData, setCheckoutData] = useState({
    quantity: 0,
    unitPrice: 0,
    productId: 0,
    totalAmount: 0,
    deliveryType: 1,
  })
  const [tabId, setSelectedTab] = useState(0);
  const [profile, setProfile] = useState(localStorage.getItem('profile') || false);
  const [profileDoc, setProfileDoc] = useState(localStorage.getItem('document') || false);
  const status = localStorage.getItem("status");

  // setTimeout(() => {
  //   console.log(checkoutData.quantity, parseFloat(checkoutData.unitPrice))
  // }, 4000);
  const role = localStorage.getItem("role");
  const customerId = localStorage.getItem("customerID");
  const encryptedCustomerId = encrypt(customerId);
  const encodedCustomerIdString = encodeURIComponent(encryptedCustomerId);

  const customerNumberId = Number(customerId);
  const encryptCustomerNumber = encrypt(customerNumberId);
  const encodedCustomerNumberString = encodeURIComponent(encryptCustomerNumber);
  
  // console.log(typeof customerId)

  const encryptedTabId = encrypt(tabId);
  const encodedTabIdString = encodeURIComponent(encryptedTabId);
  // console.log(encryptedTabId, encodedTabIdString)

  const [isPageVisible, setIsPageVisible] = useState(true);

  const handleVisibilityChange = () => {
    setIsPageVisible(document.visibilityState === 'visible');
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
  
  useEffect(() => {
    // Check if both quantity and unitPrice are numbers
    if (!isNaN(checkoutData.quantity) && !isNaN(checkoutData.unitPrice)) {
      const quantity = parseFloat(checkoutData.quantity);
      const unitPrice = parseFloat(checkoutData.unitPrice);
      
      // Calculate totalAmount
      const totalAmount = quantity * unitPrice;

      // Update the state with the new totalAmount
      setCheckoutData(prevData => ({
        ...prevData,
        totalAmount: parseFloat(totalAmount.toFixed(2)), // Assuming you want to round to 2 decimal places
      }));
    }
  }, [checkoutData.quantity, checkoutData.unitPrice]);

  // useEffect(() => {
  //   fetchOrderData(tabId);
  // }, [tabId]);

  // const isTokenExpired = (token) => {
  //   if (!token) {
  //     return true; 
  //   }

  //   try {
  //     const decodedToken = jwtDecode(token);
  //     const currentTimestamp = Math.floor(Date.now() / 1000);
  //     return decodedToken.exp < currentTimestamp;
  //   } catch (error) {
  //     console.error("Error decoding token:", error);
  //     return true;
  //   }
  // };
  const toast = useToast();



  const handleLogin = async (loginData) => {

    const encryptedCredentials = encrypt(loginData);
    // console.log('login details',encryptedCredentials)
    const encodedString = encodeURIComponent(encryptedCredentials);
    // console.log(encryptedCredentials)
    try{
      let response = await axios.post(`https://apiliveservice.myeasygas.com/api/Onboarding/login?request=${encodedString}`);
      
      console.log(response)
      // console.log(response.data.data.changePassword)
      // response.data.data.changePassword === true && setChangePassword(true)
      if (response.data.data.changePassword === true) {
        setChangePassword(true);
      }

      if (response.data.data.isProfileCompleted === 1) {
        // console.log('Setting profile to true');

        setProfile(true);
        localStorage.setItem('profile', 'true'); // Store profile state in localStorage
      } else {
        // console.log('Setting profile to false');

        setProfile(false);
        localStorage.setItem('profile', 'false'); // Store profile state in localStorage
      }

      if (response.data.data.isDocumentCompleted === 1){
        // console.log('Setting document to true');

        setProfileDoc(true);
        localStorage.setItem('document', 'true'); // Store profile state in localStorage
      }else {
        // console.log('Setting document to false');

        setProfileDoc(false);
        localStorage.setItem('document', 'false'); // Store profile state in localStorage
      }

      if (response.data.data.profileStatus === 1){
        localStorage.setItem("status", "1");
      }
            
      localStorage.setItem("token", response.data.data.token);
      localStorage.setItem("role", response.data.data.role);
      if (response.data.data.role === 'Customer' || response.data.data.role === 'Vendor' || response.data.data.role === 'Delivery' ) {
        localStorage.setItem("customerID", Number(response.data.data.userID));
        // const storageKey = response.data.data.role === 'Customer' ? 'customerID' : 'vendorID';
        // localStorage.setItem(storageKey, Number(response.data.data.userID));
      } else if(response.data.data.role === 'Admin') {
        localStorage.setItem("adminID", response.data.data.userID);
      }else{
        console.log('ID failed tuff')
      }
      // console.log(response)
      
      return response;
      
    }catch (error) {
        // console.log(error);
        // console.log(error.response); 
        // console.error('Login failed:', error);
        // console.log('Login failed:', error.response.data.message);
        // return error.response;

        toast({
          title: 'Error!',
          description: error.response.data.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
          position:'top-center'
        });
       
      };
      
  };

 

  const handleChangePassword = (passwordData) => {

     const token = localStorage.getItem("token");

    const encryptedCredentials = encrypt(passwordData);
    // console.log(encryptedCredentials);
    const encodedString = encodeURIComponent(encryptedCredentials);
    // console.log(encodedString);
    axios.patch(`https://apiliveservice.myeasygas.com/api/Onboarding/changeadminpassword?request=${encodedString}`,
        {},
        {
            headers:{
                Authorization: `Bearer ${token}`
            } 
        }
     ).then((response) => {
        const res = response.data
        // console.log(decrypt(res))
        
       
      })
      .catch((error) => {
        // console.log(error);
        // console.log(error.response); 
        // console.error('Login failed:', error);
        // console.log('failed:', error);
        toast({
          title: 'Error!',
          description: error.response.data,
          status: 'error',
          duration: 9000,
          isClosable: true,
          position:'top-center'
        });
      });
  };


  // THIS IS THE FUNCTION THAT CALLS THE LIST OF THE ADMIN

  const adminData = async () => {    

    const token = localStorage.getItem("token");

    const response = await axios.get(`https://apiliveservice.myeasygas.com/api/Dashboard/admins`, 
    {
      headers:{
        Authorization: `Bearer ${token}`
      } 
    });

    const res = response.data      
    const decryptedData = decrypt(res);
    const decodedString = decodeURIComponent(decryptedData);
    const parsedData = JSON.parse(decrypt(res))
    // console.log('Decrypted Data:', decodedString);
    // console.log(JSON.parse(decrypt(res))) 
    // console.log(parsedData);      
        
    return parsedData;
   
  };

  const {data: adminsData , isLoading: isLoadingAdmin , isError: isErrorAdmin, } = useQuery({
    queryKey: ['admins'],
    queryFn: adminData,
    // refetchInterval: 3000,
    // enabled: role === 'Admin'
    refetchInterval: isPageVisible && role === 'Admin' ? 1200000 : false, // Refetch every 20 minutes if the page is visible
    enabled: isPageVisible && role === 'Admin', // Only enable query if the page is visible and role is Admin
    staleTime: 600000, // Data is considered fresh for 10 minute
    cacheTime: 900000, // Data is cached for 15 minutes
  });


  // THIS IS THE FUNCTION THAT CALLS THE LIST OF THE ONBOARDING REQUEST


  const adminBoardingRequest = async () => {    

    const token = localStorage.getItem("token");

    const response = await axios.get(`https://apiliveservice.myeasygas.com/api/Dashboard/onboardingrequests`, 
    {
      headers:{
        Authorization: `Bearer ${token}`
      } 
    });

    const res = response.data      
    const decryptedData = decrypt(res);
    const decodedString = decodeURIComponent(decryptedData);
    const parsedData = JSON.parse(decrypt(res))
    // console.log('Decrypted Data:', decodedString);
    // console.log(JSON.parse(decrypt(res))) 
    // console.log(parsedData);      
        
    return parsedData;
   
  };

  const {data: adminBoardingData , isLoading: isLoadingBoarding , isError: isErrorBoarding, } = useQuery({
    queryKey: ['onboardingRequest'],
    queryFn: adminBoardingRequest,
    // refetchInterval: 2000,
    // enabled: role === 'Admin'
    refetchInterval: isPageVisible && role === 'Admin' ? 1200000 : false, 
    enabled: isPageVisible && role === 'Admin',
    staleTime: 600000, 
    cacheTime: 900000, 
  });


  // THIS IS THE FUNCTION THAT CALLS THE DASHBOARD NUMBERS

  const dashboardCount = async () => {    

    const token = localStorage.getItem("token")
    // console.log("token goes here",token)
    const response = await axios.get(`https://apiliveservice.myeasygas.com/api/Dashboard/dashboardcount`, 
    {
      headers:{
        Authorization: `Bearer ${token}`
      } 
    });

    const res = response.data      
    // const decryptedData = decrypt(res);
    // const decodedString = decodeURIComponent(decryptedData);
    const parsedData = JSON.parse(decrypt(res))
    // console.log('Decrypted Data:', decodedString);
    // console.log(JSON.parse(decrypt(res))) 
    // console.log(parsedData);      
        
    return parsedData;
   
  };
  
  const {data: adminDashboardCount , isLoading: isLoadingCount , isError: isErrorCount, } = useQuery({
    queryKey: ['dashboardDataCount'],
    queryFn: dashboardCount,
    // refetchInterval: 3000,
    // enabled: role === 'Admin'
    refetchInterval: isPageVisible && role === 'Admin' ? 1200000 : false, 
    enabled: isPageVisible && role === 'Admin',
    staleTime: 600000, 
    cacheTime: 900000,
  });

   

// THIS IS THE FUNCTION THAT CALLS THE PRODUCT REQUEST

  const adminProductRequest = async () => {    

    const token = localStorage.getItem("token")
    // console.log("token goes here",token)
    const response = await axios.get(`https://apiliveservice.myeasygas.com/api/Dashboard/productsrequests`, 
    {
      headers:{
        Authorization: `Bearer ${token}`
      } 
    });

    const res = response.data      
    // console.log();
    const decryptedData = decrypt(res);
    const decodedString = decodeURIComponent(decryptedData);
    const parsedData = JSON.parse(decrypt(res))
    // console.log('Decrypted Data:', decodedString);
    // console.log(JSON.parse(decrypt(res))) 
    // console.log(parsedData);      
        
    return parsedData;
   
  };

  const {data: adminProductData , isLoading: isLoadingProduct , isError: isErrorProduct, } = useQuery({
    queryKey: ['productRequest'],
    queryFn: adminProductRequest,
    // refetchInterval: 2000,
    // enabled: role === 'Admin'
    refetchInterval: isPageVisible && role === 'Admin' ? 1200000 : false, 
    enabled: isPageVisible && role === 'Admin',
    staleTime: 600000, 
    cacheTime: 900000,
  });

  // THIS IS THE FUNCTION THAT CALLS THE VALIDATED PLANTS

  const adminValidatedPlants = async () => {    

    const token = localStorage.getItem("token")
    // console.log("token goes here",token)
    const response = await axios.get(`https://apiliveservice.myeasygas.com/api/Dashboard/validatedcustomers`, 
    {
      headers:{
        Authorization: `Bearer ${token}`
      } 
    });

    const res = response.data      
    const decryptedData = decrypt(res);
    const decodedString = decodeURIComponent(decryptedData);
    const parsedData = JSON.parse(decrypt(res))
    // console.log('Decrypted Data:', decodedString);
    // console.log(JSON.parse(decrypt(res))) 
    // console.log(parsedData);      
        
    return parsedData;
   
  };

  const {data: validatedPlantData , isLoading: isLoadingCustomer , isError: isErrorCustomer } = useQuery({
    queryKey: ['validatedPlants'],
    queryFn: adminValidatedPlants,
    // refetchInterval: 2000,
    // enabled: role === 'Admin'
    refetchInterval: isPageVisible && role === 'Admin' ? 1200000 : false, 
    enabled: isPageVisible && role === 'Admin',
    staleTime: 600000, 
    cacheTime: 900000,
  });


  // THIS IS THE FUNCTION THAT CALLS THE REJECTED PLANTS

  const adminRejectedPlants = async () => {    

    const token = localStorage.getItem("token")
    // console.log("token goes here",token)
    const response = await axios.get(`https://apiliveservice.myeasygas.com/api/Dashboard/rejectedcustomers`, 
    {
      headers:{
        Authorization: `Bearer ${token}`
      } 
    });

    const res = response.data      
    const decryptedData = decrypt(res);
    const decodedString = decodeURIComponent(decryptedData);
    const parsedData = JSON.parse(decrypt(res))
    // console.log('Decrypted Data:', decodedString);
    // console.log(JSON.parse(decrypt(res))) 
    // console.log(parsedData);      
        
    return parsedData;
   
  };

  const {data: rejectedPlantData , isLoading: isLoadingRejectedPlant , isError: isErrorRejectedPlant, } = useQuery({
    queryKey: ['rejectedPlants'],
    queryFn: adminRejectedPlants,
    // refetchInterval: 2000,
    // enabled: role === 'Admin'
    refetchInterval: isPageVisible && role === 'Admin' ? 1200000 : false, 
    enabled: isPageVisible && role === 'Admin',
    staleTime: 600000, 
    cacheTime: 900000,
  });




  // THIS IS THE FUNCTION THAT CALLS THE VALIDATED TERMINALS

  const adminValidatedTerminals = async () => {    

    const token = localStorage.getItem("token")
    // console.log("token goes here",token)
    const response = await axios.get(`https://apiliveservice.myeasygas.com/api/Dashboard/validatedterminals`, 
    {
      headers:{
        Authorization: `Bearer ${token}`
      } 
    });

    const res = response.data      
    const decryptedData = decrypt(res);
    const decodedString = decodeURIComponent(decryptedData);
    const parsedData = JSON.parse(decrypt(res))
    // console.log('Decrypted Data:', decodedString);
    // console.log(JSON.parse(decrypt(res))) 
    // console.log(parsedData);      
        
    return parsedData;
   
  };

  const {data: validatedTerminalData , isLoading: isLoadingValidated , isError: isErrorValidated, } = useQuery({
    queryKey: ['validatedTerminals'],
    queryFn: adminValidatedTerminals,
    // refetchInterval: 2000,
    // enabled: role === 'Admin'
    refetchInterval: isPageVisible && role === 'Admin' ? 1200000 : false, 
    enabled: isPageVisible && role === 'Admin',
    staleTime: 600000, 
    cacheTime: 900000,
  });


   // THIS IS THE FUNCTION THAT CALLS THE REJECTED TERMINALS

   const adminRejectedTerminals = async () => {    

    const token = localStorage.getItem("token")
    // console.log("token goes here",token)
    const response = await axios.get(`https://apiliveservice.myeasygas.com/api/Dashboard/rejectedterminals`, 
    {
      headers:{
        Authorization: `Bearer ${token}`
      } 
    });

    const res = response.data      
    const decryptedData = decrypt(res);
    const decodedString = decodeURIComponent(decryptedData);
    const parsedData = JSON.parse(decrypt(res))
    // console.log('Decrypted Data:', decodedString);
    // console.log(JSON.parse(decrypt(res))) 
    // console.log(parsedData);      
        
    return parsedData;
   
  };

  const {data: rejectedTerminalData , isLoading: isLoadingRejected , isError: isErrorRejected, } = useQuery({
    queryKey: ['rejectedTerminals'],
    queryFn: adminRejectedTerminals,
    // refetchInterval: 2000,
    // enabled: role === 'Admin'
    refetchInterval: isPageVisible && role === 'Admin' ? 1200000 : false, 
    enabled: isPageVisible && role === 'Admin',
    staleTime: 600000, 
    cacheTime: 900000,
  });
 

  // THIS IS THE FUNCTION THAT CALLS THE VALIDATED TRUCKERS

  const adminValidatedTruckers = async () => {    

    const token = localStorage.getItem("token")
    // console.log("token goes here",token)
    const response = await axios.get(`https://apiliveservice.myeasygas.com/api/Dashboard/validatedtrukers`, 
    {
      headers:{
        Authorization: `Bearer ${token}`
      } 
    });

    const res = response.data      
    const decryptedData = decrypt(res);
    const decodedString = decodeURIComponent(decryptedData);
    const parsedData = JSON.parse(decrypt(res))
    // console.log('Decrypted Data:', decodedString);
    // console.log(JSON.parse(decrypt(res))) 
    // console.log(parsedData);      
        
    return parsedData;
   
  };

  const {data: validatedTruckerData , isLoading: isLoadingTrucker , isError: isErrorTrucker } = useQuery({
    queryKey: ['validatedTruckers'],
    queryFn: adminValidatedTruckers,
    // refetchInterval: 2000,
    // enabled: role === 'Admin' || role === 'Customer'
    refetchInterval: isPageVisible && (role === 'Admin' || role === 'Customer') ? 1200000 : false, 
    enabled: isPageVisible && (role === 'Admin' || role === 'Customer'),
    staleTime: 600000, 
    cacheTime: 900000,
  });


   // THIS IS THE FUNCTION THAT CALLS THE REJECTED TRUCKERS

   const adminRejectedTruckers = async () => {    

    const token = localStorage.getItem("token")
    // console.log("token goes here",token)
    const response = await axios.get(`https://apiliveservice.myeasygas.com/api/Dashboard/rejectedtruckers`, 
    {
      headers:{
        Authorization: `Bearer ${token}`
      } 
    });

    const res = response.data      
    const decryptedData = decrypt(res);
    const decodedString = decodeURIComponent(decryptedData);
    const parsedData = JSON.parse(decrypt(res))
    // console.log('Decrypted Data:', decodedString);
    // console.log(JSON.parse(decrypt(res))) 
    // console.log(parsedData);      
        
    return parsedData;
   
  };

  const {data: rejectedTruckerData , isLoading: isLoadingRejectedTrucker , isError: isErrorRejectedTrucker } = useQuery({
    queryKey: ['rejectedTruckers'],
    queryFn: adminRejectedTruckers,
    // refetchInterval: 2000,
    // enabled: role === 'Admin'
    refetchInterval: isPageVisible && role === 'Admin' ? 1200000 : false, 
    enabled: isPageVisible && role === 'Admin',
    staleTime: 600000, 
    cacheTime: 900000,
  });
  

  
 // THIS IS THE FUNCTION THAT CALLS THE PRODUCTS FOR CUSTOMERS

 const productCustomers = async () => {    

  const token = localStorage.getItem("token")
  // console.log("token goes here",token)
  const response = await axios.get(`https://apiliveservice.myeasygas.com/api/Product/productsforpurchase`, 
  {
    headers:{
      Authorization: `Bearer ${token}`
    } 
  });

  const res = response.data      
  const decryptedData = decrypt(res);
  const decodedString = decodeURIComponent(decryptedData);
  const parsedData = JSON.parse(decrypt(res))
  // console.log('Decrypted Data:', decodedString);
  // console.log(JSON.parse(decrypt(res))) 
  // console.log(parsedData);      
      
  return parsedData;
 
};

const {data: customerProductData , isLoading: isLoadingCustomerProduct , isError: isErrorCustomerProduct } = useQuery({
  queryKey: ['customerProduct'],
  queryFn: productCustomers,
  // refetchInterval: 2000,
  // enabled: role === 'Customer'
  refetchInterval: isPageVisible && role === 'Customer' ? 1200000 : false, 
  enabled: isPageVisible && role === 'Customer',
  staleTime: 600000, 
  cacheTime: 900000,
});


 // THIS IS THE FUNCTION THAT CALLS THE APPROVED PRODUCTS FOR ADMIN

 const approvedProduct = async () => {    

  const token = localStorage.getItem("token")
  // console.log("token goes here",token)
  const response = await axios.get(`https://apiliveservice.myeasygas.com/api/Product/products`, 
  {
    headers:{
      Authorization: `Bearer ${token}`
    } 
  });

  const res = response.data      
  const decryptedData = decrypt(res);
  const decodedString = decodeURIComponent(decryptedData);
  const parsedData = JSON.parse(decrypt(res))
  // console.log('Decrypted Data:', decodedString);
  // console.log(JSON.parse(decrypt(res))) 
  // console.log(parsedData);      
      
  return parsedData;
 
};

const {data: approvedProductData , isLoading: isLoadingapprovedProduct , isError: isErrorapprovedProduct } = useQuery({
  queryKey: ['approvedProduct'],
  queryFn: approvedProduct,
  // refetchInterval: 2000,
  // enabled: role === 'Admin'
  refetchInterval: isPageVisible && role === 'Admin' ? 1200000 : false, 
  enabled: isPageVisible && role === 'Admin',
  staleTime: 600000, 
  cacheTime: 900000,
});



//THIS IS THE FUNCTION THAT CALLS THE LANDING PAGE PRODUCTS

  const landingPageProduct = async () => {    

    // const token = localStorage.getItem("token")
    // console.log("token goes here",token)
    const response = await axios.get(`https://apiliveservice.myeasygas.com/api/Product/getavailableproducts`)
    // {
    //   headers:{
    //     Authorization: `Bearer ${token}`
    //   } 
    // });

    const res = response.data      
    const decryptedData = decrypt(res);
    const decodedString = decodeURIComponent(decryptedData);
    const parsedData = JSON.parse(decrypt(res))
    // console.log('Decrypted Data:', decodedString);
    // console.log(JSON.parse(decrypt(res))) 
    // console.log(parsedData);      
        
    return parsedData;
  
  };

  const {data: landingPageProductData , isLoading: isLoadingLandingProduct , isError: isErrorLandingProduct } = useQuery({
    queryKey: ['landingPageProduct'],
    queryFn: landingPageProduct,
    // refetchInterval: 2000,
    refetchInterval: isPageVisible ? 1200000 : false, 
  });



// THIS IS THE FUNCTION FOR GETTING THE CUSTOMER PROFILE

const fetchCustomerData = async (customerId) => {    
  
  const token = localStorage.getItem("token")
  // console.log("token goes here",token)
  const response = await axios.get(`https://apiliveservice.myeasygas.com/api/Profile/profile?request=${customerId}`, 
  {
    headers:{
      Authorization: `Bearer ${token}`
    } 
  }
  );
  
  const res = response.data      
  // console.log('ID response',res)
  const decryptedData = decrypt(res);
  const decodedString = decodeURIComponent(decryptedData);
  const parsedData = JSON.parse(decrypt(res))
  // console.log('Decrypted Data:', decodedString);
  // console.log(JSON.parse(decrypt(res))) 
  // console.log(parsedData);      
      
  return parsedData;
 
};

const {data: customerProfileData , isLoading: isLoadingCustomerProfileData , isError: isErrorCustomerProfileData } = useQuery({
  queryKey: ['customerProfileData'],
  queryFn: () => fetchCustomerData(encodedCustomerIdString),
  // refetchInterval: 2000,
  refetchInterval: false,
  refetchIntervalInBackground: true,
  refetchOnWindowFocus: false,
  // enabled: role === 'Customer'
  // enabled: role === 'Customer' || role === 'Vendor' || role === 'Delivery'
  enabled: isPageVisible && (role === 'Customer' || role === 'Vendor' || role === 'Delivery'),
  // staleTime: 900000, 
  // cacheTime: 1200000,

});


// THIS IS THE FUNCTION FOR GETTING SALES REP DETAILS

const fetchSalesRepsData = async (customerId) => {    
  
  const token = localStorage.getItem("token")
  // console.log("token goes here",token)
  const response = await axios.get(`https://apiliveservice.myeasygas.com/api/Profile/FetchCustomerMultipleAddressByCustomerID?id=${customerId}`, 
  {
    headers:{
      Authorization: `Bearer ${token}`
    } 
  }
  );
  
  const res = response.data      
  // console.log('ID response',res)
  const decryptedData = decrypt(res);
  const decodedString = decodeURIComponent(decryptedData);
  const parsedData = JSON.parse(decrypt(res))
  // console.log('Decrypted Data:', decodedString);
  // console.log(JSON.parse(decrypt(res))) 
  // console.log(parsedData);      
      
  return parsedData;
 
};

const {data: salesRepsProfileData , isLoading: isLoadingSalesRepsProfileData , isError: isErrorSalesRepsProfileData } = useQuery({
  queryKey: ['salesRepsProfileData'],
  queryFn: () => fetchSalesRepsData(encodedCustomerNumberString),
  // refetchInterval: 2000,
  refetchInterval: false,
  refetchIntervalInBackground: true,
  refetchOnWindowFocus: false,
  // enabled: role === 'Customer'
  // refetchInterval: isPageVisible && role === 'Admin' ? 5000 : false, 
  enabled: isPageVisible && role === 'Customer',
 // staleTime: 900000, 
  // cacheTime: 1200000,

});

// THIS IS THE FUNCTION FOR GETTING THE NUMBER OF VEHICLES

const deliveryVehicles = async () => {    

  const token = localStorage.getItem("token")
  // console.log("token goes here",token)
  const response = await axios.get(`https://apiliveservice.myeasygas.com/api/Profile/vehicles`, 
  {
    headers:{
      Authorization: `Bearer ${token}`
    } 
  });

  const res = response.data      
  const decryptedData = decrypt(res);
  const decodedString = decodeURIComponent(decryptedData);
  const parsedData = JSON.parse(decrypt(res))
  // console.log('Decrypted Data:', decodedString);
  // console.log(JSON.parse(decrypt(res))) 
  // console.log('Vehicle',parsedData);      
      
  return parsedData;
 
};

const {data: vehiclesData , isLoading: isLoadingVehicle , isError: isErrorVehicle } = useQuery({
  queryKey: ['deliveryVehicles'],
  queryFn: deliveryVehicles,
  // refetchInterval: 2000,
  // enabled: role === 'Delivery'
  refetchInterval: isPageVisible && role === 'Delivery' ? 1200000 : false, 
  enabled: isPageVisible && role === 'Delivery',
  staleTime: 600000, 
  cacheTime: 900000,
});


const handleTabChange = (newTabId) => {
  // Adjust the index numbers for "Successful" and "Cancelled" tabs
  const adjustedTabId = newTabId === 1 ? 3 : newTabId === 2 ? -2 : newTabId;
  // console.log('Selected Tab:', adjustedTabId);
  setSelectedTab(adjustedTabId);
};

// THIS IS THE FUNCTION FOR GETTING THE VENDORS PRODUCTS

const vendorProduct = async () => {    

  const token = localStorage.getItem("token")
  // console.log("token goes here",token)
  const response = await axios.get(`https://apiliveservice.myeasygas.com/api/Product/getVendorProducts`, 
  {
    headers:{
      Authorization: `Bearer ${token}`
    } 
  });

  const res = response.data      
  const decryptedData = decrypt(res);
  const decodedString = decodeURIComponent(decryptedData);
  const parsedData = JSON.parse(decrypt(res))
  // console.log('Decrypted Data:', decodedString);
  // console.log(JSON.parse(decrypt(res))) 
  // console.log('Vehicle',parsedData);      
      
  return parsedData;
 
};

const {data: vendorProductData , isLoading: isLoadingVendorProduct , isError: isErrorVendorProduct } = useQuery({
  queryKey: ['vendorProducts'],
  queryFn: vendorProduct,
  // refetchInterval: 2000,
  // enabled: role === 'Vendor'
  refetchInterval: isPageVisible && role === 'Vendor' ? 1200000 : false, 
  enabled: isPageVisible && role === 'Vendor',
  staleTime: 600000, 
  cacheTime: 900000,
});


// THIS IS THE FUNCTION FOR GETTING THE ORDER LIST

const fetchOrderData = async (tabId) => {
 

  // console.log("tab id goes here", tabId) 
    const token = localStorage.getItem("token")
    const response = await axios.get(`https://apiliveservice.myeasygas.com/api/Product/orderlist?value=${tabId}`, 
    {
      headers:{
        Authorization: `Bearer ${token}`
      } 
    }
    );
    
    const res = response.data  
    // setTableData(res);    
    // console.log('ID response',res)
    const decryptedData = decrypt(res);
    const decodedString = decodeURIComponent(decryptedData);
    const parsedData = JSON.parse(decrypt(res))
    // setTableData(parsedData);    
    // console.log('Decrypted Data:', decodedString);
    // console.log(JSON.parse(decrypt(res))) 
    // console.log(parsedData);      
        
    return parsedData;


};

const {data: orderData , isLoading: isLoadingOrderData , isError: isErrorOrderData } = useQuery({
  queryKey: ['OrderData', encodedTabIdString],
  queryFn: () => fetchOrderData(encodedTabIdString),
  // refetchInterval: 2000,
  refetchInterval: false,
  refetchIntervalInBackground: true,
  refetchOnWindowFocus: false,
  enabled: isPageVisible && (role === 'Vendor' || role === 'Admin')

});


// THIS IS THE FUNCTION FOR GETTING CUSTOMER HISTORY

const fetchCustomerHistory = async (tabId) => {
 

  // console.log("tab id goes here", tabId) 
    const token = localStorage.getItem("token")
    const response = await axios.get(`https://apiliveservice.myeasygas.com/api/Product/customerOrderList?value=${tabId}`, 
    {
      headers:{
        Authorization: `Bearer ${token}`
      } 
    }
    );
    
    const res = response.data  
    // setTableData(res);    
    // console.log('ID response',res)
    const decryptedData = decrypt(res);
    const decodedString = decodeURIComponent(decryptedData);
    const parsedData = JSON.parse(decrypt(res))
    // setTableData(parsedData);    
    // console.log('Decrypted Data:', decodedString);
    // console.log(JSON.parse(decrypt(res))) 
    // console.log(parsedData);      
        
    return parsedData;


};

const {data: customerHistoryData , isLoading: isLoadingCustomerHistoryData , isError: isErrorCustomerHistoryData } = useQuery({
  queryKey: ['customerHistory', encodedTabIdString],
  queryFn: () => fetchCustomerHistory(encodedTabIdString),
  // refetchInterval: 2000,
  refetchInterval: false,
  refetchIntervalInBackground: true,
  refetchOnWindowFocus: false,
  enabled: isPageVisible && role === 'Customer' 

});


// Please update and change pleaseccl


// THIS IS THE FUNCTION TO EDIT THE CUSTOMER PROFILE

const handleUpdateCustomerProfile = (customerData) => {

  const token = localStorage.getItem("token");

 const encryptedCredentials = encrypt(customerData);
//  console.log(encryptedCredentials);
 const encodedString = encodeURIComponent(encryptedCredentials);
//  console.log(encodedString);
 axios.patch(`https://apiliveservice.myeasygas.com/api/Profile/customerprofile?request=${encodedString}`,
    {},
    {
      headers:{
        Authorization: `Bearer ${token}`
      } 
    }
  ).then((response) => {
     const res = response.data
    //  console.log('customerData',decrypt(res))
     
    
   })
   .catch((error) => {
    //  console.log(error);
    //  console.log(error.response); 
     // console.error('Login failed:', error);
    //  console.log('Customer Data failed:', error);
     toast({
      title: 'Error!',
      description: error.response.data,
      status: 'error',
      duration: 9000,
      isClosable: true,
      position:'top-center'
    });
   });
};

// THIS IS THE CHANGE PASSWORD FOR CUSTOMER 

const handleChangeCustomerPassword = (passwordData) => {

  const token = localStorage.getItem("token");

 const encryptedCredentials = encrypt(passwordData);
//  console.log(encryptedCredentials);
 const encodedString = encodeURIComponent(encryptedCredentials);
//  console.log(encodedString);
 axios.patch(`https://apiliveservice.myeasygas.com/api/Onboarding/changecustomerpassword?request=${encodedString}`,
     {},
     {
      headers:{
        Authorization: `Bearer ${token}`
      } 
     }
  ).then((response) => {
     const res = response.data
    //  console.log(decrypt(res))
     const decryptedData = decrypt(res);

     toast({
      title: 'Success!',
      description: decryptedData,
      status: 'success',
      duration: 9000,
      isClosable: true,
      position: 'top-center'
    });
    
   })
   .catch((error) => {
    //  console.log(error);
    //  console.log(error.response); 
     // console.error('Login failed:', error);
    //  console.log('Change Password failed:', error);
      const decryptedError = decrypt(error.response.data);
      // const decodedString = decodeURIComponent(decryptedError);
      // console.log('Decrypted Error:', decodedString);

      toast({
        title: 'Error!',
        description: decryptedError,
        status: 'error',
        duration: 9000,
        isClosable: true,
        position:'top-center'
      });


   });
};

// THIS IS THE API FOR UPLOADING CUSTOMER REPRESENTATIVE

const handleUpdateRepsData = (repsData) => {
  const token = localStorage.getItem("token");

 const encryptedCredentials = encrypt(repsData);
//  console.log(encryptedCredentials);
 const encodedString = encodeURIComponent(encryptedCredentials);
//  console.log(encodedString);
 axios.post(`https://apiliveservice.myeasygas.com/api/Profile/CreateNultipleAddressByProfile?request=${encodedString}`,
    {},
    {
      headers:{
        Authorization: `Bearer ${token}`
      } 
    }
  ).then((response) => {
     const res = response.data
    //  console.log('repsData',decrypt(res))
     const decryptedData = JSON.parse(decrypt(res));
     toast({
      title: 'Success!',
      description: decryptedData.Message,
      status: 'success',
      duration: 9000,
      isClosable: true,
      position: 'top-center'
    });
    
   })
   .catch((error) => {
    //  console.log(error);
    //  console.log(error.response); 
     // console.error('Login failed:', error);
    //  console.log('res Data failed:', error);
     const decryptedError = JSON.parse(decrypt(error.response.data));

     toast({
      title: 'Error!',
      description: decryptedError.Message,
      status: 'error',
      duration: 9000,
      isClosable: true,
      position:'top-center'
    });

   });
}

// THIS IS THE FUNCTION TO EDIT THE VENDOR/DELIVERY PROFILE

const handleUpdateVendorProfile = (customerData) => {

  const token = localStorage.getItem("token");

 const encryptedCredentials = encrypt(customerData);
//  console.log(encryptedCredentials);
 const encodedString = encodeURIComponent(encryptedCredentials);
//  console.log(encodedString);
 axios.patch(`https://apiliveservice.myeasygas.com/api/Profile/vendorprofile?request=${encodedString}`,
    {},
    {
      headers:{
        Authorization: `Bearer ${token}`
      } 
    }
  ).then((response) => {
     const res = response.data
    //  console.log('VendorData',decrypt(res))
     
    
   })
   .catch((error) => {
    //  console.log(error);
    //  console.log(error.response); 
     // console.error('Login failed:', error);
    //  console.log('Vendor Data failed:', error);
    toast({
      title: 'Error!',
      description: error.response.data,
      status: 'error',
      duration: 9000,
      isClosable: true,
      position:'top-center'
    });
   });
};


 // THIS IS THE FUNCTION THAT CALLS THE PRODUCTS REQUESTS FOR VENDOR

 const vendorProductRequests = async () => {    

  const token = localStorage.getItem("token")
  // console.log("token goes here",token)
  const response = await axios.get(`https://apiliveservice.myeasygas.com/api/Product/requestlist`, 
  {
    headers:{
      Authorization: `Bearer ${token}`
    } 
  });

  const res = response.data      
  // console.log('this',res)
  const decryptedData = decrypt(res);
  const decodedString = decodeURIComponent(decryptedData);
  const parsedData = JSON.parse(decrypt(res))
  // console.log('Decrypted Data:', decodedString);
  // console.log(JSON.parse(decrypt(res))) 
  // console.log(parsedData);      
      
  return parsedData;
 
};

const {data: vendorRequestsData , isLoading: isLoadingVendorRequests , isError: isErrorVendorRequests } = useQuery({
  queryKey: ['vendorRequests'],
  queryFn: vendorProductRequests,
  // refetchInterval: 2000,
  // enabled: role === 'Vendor'
  refetchInterval: isPageVisible && role === 'Vendor' ? 1200000 : false, 
  enabled: isPageVisible && role === 'Vendor',
  staleTime: 600000, 
  cacheTime: 900000,
});



  const handleSubmitId = (selectedUserId) => {
    const encryptedCredentials = encrypt(selectedUserId);
    // console.log(encryptedCredentials);
    const encodedString = encodeURIComponent(encryptedCredentials);
    // console.log(encodedString);
    const token = localStorage.getItem("token")
    // console.log("token goes here",token)
    axios.patch(
        `https://apiliveservice.myeasygas.com/api/Onboarding/validateuser?request=${encodedString}`,
        {},
        {
            headers:{
                Authorization: `Bearer ${token}`
            } 
        }
     ).then((response) => {
        const res = response.data
        // console.log(decrypt(res))
        const decryptedData = decrypt(res);
        // const decodedString = decodeURIComponent(decryptedData);
        // console.log('Decrypted Data:', decodedString);

        toast({
          title: 'Success!',
          description: decryptedData,
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top-center'
        });
       
      })
      .catch((error) => {
        // console.log(error);
        // console.log(error.response); 
        // console.log(error.response.data); 
        // console.error('Login failed:', error);
        // console.log('Validation failed:', error);
        const decryptedError = decrypt(error.response);
        // const decodedString = decodeURIComponent(decryptedError);
        // console.log('Decrypted Error:', decodedString);

        toast({
          title: 'Error!',
          description: decryptedError,
          status: 'error',
          duration: 9000,
          isClosable: true,
          position:'top-center'
        });
      });
  };


  const handleRejectId = (rejectData) => {
    const encryptedCredentials = encrypt(rejectData);
    // console.log(encryptedCredentials);
    const encodedString = encodeURIComponent(encryptedCredentials);
    // console.log(encodedString);
    const token = localStorage.getItem("token")
    // console.log("token goes here",token)
    axios.patch(
        `https://apiliveservice.myeasygas.com/api/Onboarding/rejectuser?request=${encodedString}`,
        {},
        {
            headers:{
                Authorization: `Bearer ${token}`
            } 
        }
     ).then((response) => {
        const res = response.data
        // console.log(decrypt(res))
        const decryptedData = decrypt(res);
        // const decodedString = decodeURIComponent(decryptedData);
        // console.log('Decrypted Data:', decodedString);

        toast({
          title: 'Success!',
          description: decryptedData,
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top-center'
        });
       
      })
      .catch((error) => {
        // console.log(error);
        // console.log(error.response); 
        // console.error('Login failed:', error);
        // console.log('Login failed:', error);
        const decryptedError = decrypt(error.response.data);
        // const decodedString = decodeURIComponent(decryptedError);
        // console.log('Decrypted Error:', decodedString);

        toast({
          title: 'Error!',
          description: decryptedError,
          status: 'error',
          duration: 9000,
          isClosable: true,
          position:'top-center'
        });
      });
  };


  const handleRevokeId = (selectedUserId) => {
    const encryptedCredentials = encrypt(selectedUserId);
    // console.log(encryptedCredentials);
    const encodedString = encodeURIComponent(encryptedCredentials);
    // console.log(encodedString);
    const token = localStorage.getItem("token")
    // console.log("token goes here",token)
    axios.patch(
        `https://apiliveservice.myeasygas.com/api/Onboarding/revokeuser?request=${encodedString}`,
        {},
        {
            headers:{
              Authorization: `Bearer ${token}`
            } 
        }
     ).then((response) => {
        const res = response.data
        // console.log(decrypt(res))
        const decryptedData = decrypt(res);
        // const decodedString = decodeURIComponent(decryptedData);
        // console.log('Decrypted Data:', decodedString);

        toast({
          title: 'Success!',
          description: decryptedData,
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top-center'
        });
       
      })
      .catch((error) => {
        // console.log(error);
        // console.log(error.response); 
        // console.error('Login failed:', error);
        // console.log('Login failed:', error);
        const decryptedError = decrypt(error.response.data);
        // const decodedString = decodeURIComponent(decryptedError);
        // console.log('Decrypted Error:', decodedString);

        toast({
          title: 'Error!',
          description: decryptedError,
          status: 'error',
          duration: 9000,
          isClosable: true,
          position:'top-center'
        });
      });
  };



  const handleSubmitProductId = (selectedUserId) => {
    const encryptedCredentials = encrypt(selectedUserId);
    // console.log(encryptedCredentials);
    // console.log(selectedUserId);

    const encodedString = encodeURIComponent(encryptedCredentials);
    // console.log(encodedString);
    const token = localStorage.getItem("token")
    // console.log("token goes here",token)
    axios.patch(
        `https://apiliveservice.myeasygas.com/api/Product/accept?request=${encodedString}`,
        {},
        {
            headers:{
                Authorization: `Bearer ${token}`
            } 
          }
     ).then((response) => {
       const res = response.data
      //  console.log(decrypt(response))
        const decryptedData = decrypt(res);
        // const decodedString = decodeURIComponent(decryptedData);
        // console.log('Decrypted Data:', decodedString);

        toast({
          title: 'Success!',
          description: decryptedData,
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top-center'
        });
       
      })
      .catch((error) => {
        // console.log(error);
        // console.log(error.response); 
        // console.log(error.response.data); 
        // console.error('Login failed:', error);
        // console.log('Validation failed:', error);
        const decryptedError = decrypt(error.response);
        // const decodedString = decodeURIComponent(decryptedError);
        // console.log('Decrypted Error:', decodedString);

        toast({
          title: 'Error!',
          description: decryptedError,
          status: 'error',
          duration: 9000,
          isClosable: true,
          position:'top-center'
        });
      });
  };


  const handleRejectProductId = (rejectData) => {
    const encryptedCredentials = encrypt(rejectData);
    // console.log(encryptedCredentials);
    const encodedString = encodeURIComponent(encryptedCredentials);
    // console.log(encodedString);
    const token = localStorage.getItem("token")
    // console.log("token goes here",token)
    axios.patch(
        `https://apiliveservice.myeasygas.com/api/Product/decline?request=${encodedString}`,
        {},
        {
            headers:{
                Authorization: `Bearer ${token}`
            } 
        }
     ).then((response) => {
        const res = response.data
        // console.log(decrypt(res))
        const decryptedData = decrypt(res);
        // const decodedString = decodeURIComponent(decryptedData);
        // console.log('Decrypted Data:', decodedString);

        toast({
          title: 'Success!',
          description: decryptedData,
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top-center'
        });
       
      })
      .catch((error) => {
        // console.log(error);
        // console.log(error.response); 
        // console.error('Login failed:', error);
        // console.log('Login failed:', error);
        const decryptedError = decrypt(error.response.data);
        // const decodedString = decodeURIComponent(decryptedError);
        // console.log('Decrypted Error:', decodedString);

        toast({
          title: 'Error!',
          description: decryptedError,
          status: 'error',
          duration: 9000,
          isClosable: true,
          position:'top-center'
        });
      });
  };


  const handlePurchaseProduct = (purchaseData) => {
    const encryptedCredentials = encrypt(purchaseData);
    // console.log(encryptedCredentials);
    const encodedString = encodeURIComponent(encryptedCredentials);
    // console.log(encodedString);
    const token = localStorage.getItem("token")
    axios.post(
        `https://apiliveservice.myeasygas.com/api/Product/purchaseproduct?request=${encodedString}`,
        {},
        {
            headers:{
                Authorization: `Bearer ${token}`
            } 
        }
     ).then((response) => {
        const res = response.data
        // console.log(decrypt(res))
        const decryptedData = decrypt(res);
        // const decodedString = decodeURIComponent(decryptedData);
        // console.log('Decrypted Data:', decodedString);

        toast({
          title: 'Success!',
          description: decryptedData,
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top-center'
        });
       
      })
      .catch((error) => {
        // console.log(error);
        // console.log(error.response); 
        // console.error('Login failed:', error);
        // console.log('Login failed:', error);
        const decryptedError = decrypt(error.response.data);
        // const decodedString = decodeURIComponent(decryptedError);
        // console.log('Decrypted Error:', decodedString);

        toast({
          title: 'Error!',
          description: decryptedError,
          status: 'error',
          duration: 9000,
          isClosable: true,
          position:'top-center'
        });
      });
  };

  // POST API FOR CUSTOMER DOCUMENT

  const handleUploadCustomer = (data) => {
    const encryptedCredentials = encrypt(data);
    // console.log(encryptedCredentials);
    const encodedString = encodeURIComponent(encryptedCredentials);
    // console.log(encodedString);
    const token = localStorage.getItem("token")
    axios.patch(
        `https://apiliveservice.myeasygas.com/api/Profile/customerdocument?request=${encodedString}`,
        {},
        {
            headers:{
                Authorization: `Bearer ${token}`
            } 
        }
     ).then((response) => {
        const res = response.data
        // const decryptedData = decrypt(res);
        const decryptedData = JSON.parse(decrypt(res));
        // const message = decryptedData.Message;
        // console.log(message);
        // console.log(typeof decryptedData)
        // const decodedString = decodeURIComponent(decryptedData);
        // console.log('Decrypted Data:', decodedString);

        toast({
          title: 'Success!',
          description: decryptedData.Message,
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top-center'
        });
       
      })
      .catch((error) => {
        // console.log(error);
        // console.log(error.response); 
        // console.error('Login failed:', error);
        // console.log('Login failed:', error);
        const decryptedError = JSON.parse(decrypt(error.response.data));

        // console.log('ERR failed:', typeof decryptedError);

        // const decodedString = decodeURIComponent(decryptedError);
        // console.log('Decrypted Error:', decodedString);

        toast({
          title: 'Error!',
          description: decryptedError.Message,
          status: 'error',
          duration: 9000,
          isClosable: true,
          position:'top-center'
        });
      });
  };

  // POST API FOR VENDOR DOCUMENT

  const handleUploadVendor = (data) => {
    const encryptedCredentials = encrypt(data);
    // console.log(encryptedCredentials);
    const encodedString = encodeURIComponent(encryptedCredentials);
    // console.log(encodedString);
    const token = localStorage.getItem("token")
    axios.patch(
        `https://apiliveservice.myeasygas.com/api/Profile/vendordocument?request=${encodedString}`,
        {},
        {
            headers:{
                Authorization: `Bearer ${token}`
            } 
        }
     ).then((response) => {
        const res = response.data
        // console.log(decrypt(res))
        const decryptedData = JSON.parse(decrypt(res));
        // const decodedString = decodeURIComponent(decryptedData);
        // console.log('Decrypted Data:', decodedString);

        toast({
          title: 'Success!',
          description: decryptedData.Message,
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top-center'
        });
       
      })
      .catch((error) => {
        // console.log(error);
        // console.log(error.response); 
        // console.error('Login failed:', error);
        // console.log('failed:', error);
        const decryptedError = JSON.parse(decrypt(error.response.data));
        // const decodedString = decodeURIComponent(decryptedError);
        // console.log('Decrypted Error:', decodedString);

        toast({
          title: 'Error!',
          description: decryptedError.Message,
          status: 'error',
          duration: 9000,
          isClosable: true,
          position:'top-center'
        });
      });
  };

  // POST API FOR DELIVERY DOCUMENT

  const handleUploadDelivery = (data) => {
    const encryptedCredentials = encrypt(data);
    // console.log(encryptedCredentials);
    const encodedString = encodeURIComponent(encryptedCredentials);
    // console.log(encodedString);
    const token = localStorage.getItem("token")
    axios.patch(
        `https://apiliveservice.myeasygas.com/api/Profile/DeliveryDocument?request=${encodedString}`,
        {},
        {
            headers:{
                Authorization: `Bearer ${token}`
            } 
        }
     ).then((response) => {
        const res = response.data
        // console.log(decrypt(res))
        const decryptedData = JSON.parse(decrypt(res));
        // const decodedString = decodeURIComponent(decryptedData);
        // console.log('Decrypted Data:', decodedString);

        toast({
          title: 'Success!',
          description: decryptedData.Message,
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top-center'
        });
       
      })
      .catch((error) => {
        // console.log(error);
        // console.log(error.response); 
        // console.error('Login failed:', error);
        // console.log('failed:', error);
        const decryptedError = JSON.parse(decrypt(error.response.data));
        // const decodedString = decodeURIComponent(decryptedError);
        // console.log('Decrypted Error:', decodedString);

        toast({
          title: 'Error!',
          description: decryptedError.Message,
          status: 'error',
          duration: 9000,
          isClosable: true,
          position:'top-center'
        });
      });
  };

  // POST API FOR UPLOADING VEHICLES

  const handleUploadVehicles = (data) => {
    const encryptedCredentials = encrypt(data);
    // console.log(encryptedCredentials);
    const encodedString = encodeURIComponent(encryptedCredentials);
    // console.log(encodedString);
    const token = localStorage.getItem("token")
    axios.post(
        `https://apiliveservice.myeasygas.com/api/Profile/createdeliveryvehicle?request=${encodedString}`,
        {},
        {
            headers:{
              Authorization: `Bearer ${token}`
            } 
        }
     ).then((response) => {
        const res = response.data
        // console.log(decrypt(res))
        const decryptedData = JSON.parse(decrypt(res));
        // const decodedString = decodeURIComponent(decryptedData);
        // console.log('Decrypted Data:', decodedString);

        toast({
          title: 'Success!',
          description: decryptedData.Message,
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top-center'
        });
       
      })
      .catch((error) => {
        // console.log(error);
        // console.log(error.response); 
        // console.error('Login failed:', error);
        // console.log('failed:', error);
        const decryptedError = JSON.parse(decrypt(error.response.data));
        // const decodedString = decodeURIComponent(decryptedError);
        // console.log('Decrypted Error:', decodedString);

        toast({
          title: 'Error!',
          description: decryptedError.Message,
          status: 'error',
          duration: 9000,
          isClosable: true,
          position:'top-center'
        });
      });
  };

  const handleSubmitRequestId = (selectedUserId) => {
    const encryptedCredentials = encrypt(selectedUserId);
    // console.log(encryptedCredentials);
    // console.log(selectedUserId);

    const encodedString = encodeURIComponent(encryptedCredentials);
    // console.log(encodedString);
    const token = localStorage.getItem("token")
    // console.log("token goes here",token)
    axios.patch(
        `https://apiliveservice.myeasygas.com/api/Product/acceptorder?request=${encodedString}`,
        {},
        {
          headers:{
            Authorization: `Bearer ${token}`
          } 
        }
     ).then((response) => {
       const res = response.data
      //  console.log(decrypt(response))
        const decryptedData = decrypt(res);
        // const decodedString = decodeURIComponent(decryptedData);
        // console.log('Decrypted Data:', decodedString);

        toast({
          title: 'Success!',
          description: decryptedData,
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top-center'
        });
       
      })
      .catch((error) => {
        // console.log(error);
        // console.log(error.response); 
        // console.log(error.response.data); 
        // console.error('Login failed:', error);
        // console.log('Validation failed:', error);
        const decryptedError = decrypt(error.response);
        // const decodedString = decodeURIComponent(decryptedError);
        // console.log('Decrypted Error:', decodedString);

        toast({
          title: 'Error!',
          description: decryptedError,
          status: 'error',
          duration: 9000,
          isClosable: true,
          position:'top-center'
        });
      });
  };


  const handleRejectRequestId = (rejectData) => {
    const encryptedCredentials = encrypt(rejectData);
    // console.log(encryptedCredentials);
    const encodedString = encodeURIComponent(encryptedCredentials);
    // console.log(encodedString);
    const token = localStorage.getItem("token")
    // console.log("token goes here",token)
    axios.patch(
    `https://apiliveservice.myeasygas.com/api/Product/declineorder?request=${encodedString}`,
    {},
    {
      headers:{
        Authorization: `Bearer ${token}`
      } 
    }
    ).then((response) => {
      const res = response.data
      // console.log(decrypt(res))
      const decryptedData = decrypt(res);
      // const decodedString = decodeURIComponent(decryptedData);
      // console.log('Decrypted Data:', decodedString);

      toast({
        title: 'Success!',
        description: decryptedData,
        status: 'success',
        duration: 9000,
        isClosable: true,
        position: 'top-center'
      });
      
    })
    .catch((error) => {
      console.log(error);
      console.log(error.response); 
      // console.error('Login failed:', error);
      console.log('Login failed:', error);
      const decryptedError = decrypt(error.response.data);
      // const decodedString = decodeURIComponent(decryptedError);
      // console.log('Decrypted Error:', decodedString);

      toast({
        title: 'Error!',
        description: decryptedError,
        status: 'error',
        duration: 9000,
        isClosable: true,
        position:'top-center'
      });
    });
  };


  const handleLogout = () => {
    // setToken(null);
    // setUserRole(null);
    // Clear all local storage
    localStorage.clear();
    // Clear all session storage if used
    sessionStorage.clear();
    queryClient.clear(); // Clear all cached queries when logging out
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('customerID');
    localStorage.removeItem('adminID');
    localStorage.removeItem('vendorID');
    localStorage.removeItem('profile');
    localStorage.removeItem('status');
    localStorage.removeItem('document');
    localStorage.removeItem('refreshToken');
    navigate('/login');

    // localStorage.removeItem('userRole');
    // clearTimeout(idleTimeout);
  };



  // const startIdleTimeout = () => {
  //   const timeout = setTimeout(() => {
  //     handleLogout();
  //   }, 1800000); // Set your desired idle timeout duration in milliseconds

  //   setIdleTimeout(timeout);
  // };

  // const resetIdleTimeout = () => {
  //   clearTimeout(idleTimeout);
  //   startIdleTimeout();
  // };



  return (
    <AuthContext.Provider
        value={{          
          handleLogin,
          handleLogout,           
          // resetIdleTimeout,
          handleChangePassword,
          adminBoardingRequest,
          adminData,
          dashboardCount,
          adminProductRequest,
          handleSubmitId,
          handleRejectId,
          adminValidatedTerminals,
          handleRevokeId,
          adminRejectedTerminals,
          adminValidatedPlants,
          adminRejectedPlants,
          adminValidatedTruckers,
          adminRejectedTruckers,
          setChangePassword,
          handleSubmitProductId,
          handleRejectProductId,
          productCustomers,
          approvedProduct,
          fetchCustomerData,
          handleUpdateCustomerProfile,
          handleChangeCustomerPassword,
          vendorProductRequests,
          handlePurchaseProduct,
          setCheckoutData,
          handleUpdateVendorProfile,
          handleSubmitRequestId,
          handleRejectRequestId,
          deliveryVehicles,
          handleTabChange,
          fetchOrderData,
          vendorProduct,
          handleUploadCustomer,
          handleUploadVendor,
          handleUploadDelivery,
          landingPageProduct,
          handleUpdateRepsData,
          handleUploadVehicles,
          fetchSalesRepsData,
          customerId,
          // setProfile,
          tabId,
          // tableData,
          changePassword,
          adminsData,
          isLoadingAdmin,
          isErrorAdmin,
          adminBoardingData,
          isLoadingBoarding,
          isErrorBoarding,
          adminDashboardCount,
          isLoadingCount,
          isErrorCount,
          adminProductData,
          isLoadingProduct,
          isErrorProduct,
          validatedTerminalData,
          isLoadingValidated,
          isErrorValidated,
          rejectedTerminalData,
          isLoadingRejected,
          isErrorRejected,
          validatedPlantData,
          isLoadingCustomer,
          isErrorCustomer,
          rejectedPlantData,
          isLoadingRejectedPlant,
          isErrorRejectedPlant,
          validatedTruckerData,
          isLoadingTrucker,
          isErrorTrucker,
          rejectedTruckerData,
          isLoadingRejectedTrucker,
          isErrorRejectedTrucker,
          customerProductData,
          isLoadingCustomerProduct,
          isErrorCustomerProduct,
          approvedProductData,
          isLoadingapprovedProduct,
          isErrorapprovedProduct,
          customerProfileData,
          isLoadingCustomerProfileData,
          isErrorCustomerProfileData,
          vendorRequestsData,
          isLoadingVendorRequests,
          isErrorVendorRequests,
          checkoutData,
          vehiclesData,
          isErrorVehicle,
          isLoadingVehicle,
          orderData,
          isLoadingOrderData,
          isErrorOrderData,
          profile,
          status,
          vendorProductData,
          isLoadingVendorProduct,
          isErrorVendorProduct,
          profileDoc,
          landingPageProductData,
          isLoadingLandingProduct,
          isErrorLandingProduct,
          salesRepsProfileData,
          isLoadingSalesRepsProfileData,
          isErrorSalesRepsProfileData,
          customerHistoryData,
          isLoadingCustomerHistoryData,
          isErrorCustomerHistoryData
        }}
    >
        {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

